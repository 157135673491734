<script setup lang="ts">
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { useSearchStore } from '~/stores/searchStore'

const searchStore = useSearchStore()
const showSearch = () => searchStore.searchVisible = true
</script>

<template lang="pug">
header(class="py-4 bg-white shadow sticky -top-16 z-10")
  div(class="container mx-auto")
    div(class="flex items-center")
      NuxtLink.logo(to="/" class="flex flex-col gap-3 items-center justify-start")
        .logo-img(class="w-32 h-6 bg-contain bg-no-repeat bg-[url(/img/lemono-logo.svg)]")
      MainNav.nav
      HeaderUserButtons(class="mr-auto")
    .search(class="mt-3")
      .search-field(
        @click="showSearch"
        class="flex items-center gap-4 py-2 px-4"
        class="bg-white border border-gray-300 rounded-full text-gray-500 cursor-pointer"
      )
        MagnifyingGlassIcon(class="h-5")
        span جستجو در لمونو
</template>
