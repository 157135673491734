<script setup lang="ts">
import {
  ArrowRightIcon,
  MagnifyingGlassIcon,
  XMarkIcon
} from '@heroicons/vue/24/outline'
import { storeToRefs } from 'pinia'
import { useSearchStore } from '~/stores/searchStore'

useHead({
  title: 'جستجو'
})

const searchInput = ref<HTMLInputElement>()
onMounted(() => searchInput.value?.focus())

const searchStore = useSearchStore()
const { keyword, result, state } = storeToRefs(searchStore)

const clearKeyword = () => keyword.value = ''
const close = searchStore.close
const searchItemClicked = close

const escapeClose = (event: KeyboardEvent) => {
  if (event.code == 'Escape')
    close()
}
</script>

<template lang="pug">
.search(
  @keydown="escapeClose"
  tabindex="0"
  @click="close"
  class="fixed inset-0 z-50 h-screen w-full backdrop-blur bg-white/60"
)
  .container(@click.stop class="mx-auto")
    .inner(class="grid gap-10 py-10 justify-items-center h-screen content-start")
      a.logo(href="http://lemono.co" class="w-48")
        img(src="/img/lemono-logo.svg")

      .input(
        class="flex items-center gap-4 py-4 px-6 bg-gray-100 rounded-lg bg-white w-full shadow"
        class="hover:shadow-md"
      )
        .search-icon(class="shrink-0")
          MagnifyingGlassIcon.h-8.text-gray-500
        input(
          ref="searchInput"
          v-model="keyword"
          :placeholder="$t('search.placeholder')"
          class="w-full text-xl"
        )
        template(v-if="keyword.length > 0")
          .clear-button(
            @click="clearKeyword"
            class="flex items-center justify-center cursor-pointer bg-transparent rounded-full size-10 shrink-0"
            class="hover:bg-gray-200"
          )
            XMarkIcon.h-6

      template(v-if="state == 'notSearchedYet'")
        .not-searched-yet(class="flex flex-col items-center text-xl text-center text-gray-500 gap-7 font-medium")
          MagnifyingGlassIcon.h-12
          span دنبال چه محصولی هستید؟
          span نام محصول مورد نظر خود را تایپ کنید!
          span در صورت پیدا نکردن محصول مورد نظر با ما تماس بگیرید
          Button(tag="link" to="/contact" @buttonClicked="close")
            template(v-slot:icon-prefix)
              ArrowRightIcon.h-5
            span تماس با ما

      template(v-if="state == 'loading'")
        .loading
          Loading

      template(v-if="state == 'noItemFound'")
        EmptyList.lg(size="xl" text="نتیجه‌ای یافت نشد")

      template(v-if="state == 'itemsFound'")
        .search-result(class="grid gap-4 w-full overflow-y-auto items-start")
          template(v-for="item in result")
            SearchResultItem(:item="item" @itemClicked="searchItemClicked")

  .close-button(
    @click="close"
    class="absolute top-6 left-6 text-4xl p-4 cursor-pointer rounded-full flex items-center justify-center size-16"
    class="hover:bg-gray-200 lg:top-10 lg:left-10"
  )
    XMarkIcon(class="h-16")
</template>

<style lang="stylus" scoped>
.search
  --loading-color: #777
  .container
    max-width 50rem
    .input input
      @apply border-0 shadow-none px-0
</style>
