<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { storeToRefs } from 'pinia'
import { useUXStore } from '~/stores/UXStore'
import MainNavItem from './MainNavItem.vue'

const uxStore = useUXStore()
const { menuVisible } = storeToRefs(uxStore)
const toggleMenu = uxStore.toggleMenu

const menuItemClicked = () => uxStore.hideMenu()
</script>

<template lang="pug">
nav.main-nav(class="flex items-center md:justify-between lg:justify-between")
  .menu-container(
    :class="{visible: menuVisible}"
    class="fixed flex items-center justify-center size-full z-30 top-0 left-full backdrop-blur-sm bg-white/80"
    class="[&.visible]:left-0"
    class="md:relative md:bg-transparent md:w-auto md:items-center md:gap-2 md:left-0"
    class="lg:left-0"
  )
    .close-button(
      @click="toggleMenu"
      class="absolute top-10 left-10 text-4xl p-4 cursor-pointer rounded-full"
      class="flex items-center justify-center size-16 hover:bg-gray-200"
      class="md:hidden"
    )
      XMarkIcon(class="h-16")
    .menu(class="flex flex-col items-center gap-4 md:flex-row md:gap-0 lg:gap-2")
      MainNavItem(to="/" @click="menuItemClicked") {{ $t('nav.home') }}
      MainNavItem(to="/products" @click="menuItemClicked") {{ $t('nav.products') }}
      MainNavItem(to="/services" @click="menuItemClicked") خدمات
      MainNavItem(to="/support" @click="menuItemClicked") پشتیبانی
      MainNavItem(to="/article" @click="menuItemClicked") اخبار و مقالات
      MainNavItem(to="/guide" @click="menuItemClicked") {{ $t('nav.purchaseGuide') }}
      MainNavItem(to="/faq" @click="menuItemClicked") سوالات متداول
      MainNavItem(to="/about" @click="menuItemClicked") {{ $t('nav.aboutUs') }}
      MainNavItem(to="/contact" @click="menuItemClicked") {{ $t('nav.contactUs') }}
</template>
