import { defineStore } from "pinia"

export const useUXStore = defineStore('uxStore', () => {
  const menuVisible = ref(false)
  const toggleMenu = () => menuVisible.value = !menuVisible.value
  const showMenu = () => menuVisible.value = true
  const hideMenu = () => menuVisible.value = false

  return {
    menuVisible,
    toggleMenu,
    showMenu,
    hideMenu
  }
})