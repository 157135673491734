<script setup lang="ts">
import type { Toast } from '~/store/toastStore'
import {
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  CheckCircleIcon
} from '@heroicons/vue/24/outline'

const { toast } = defineProps<{
  toast: Toast
}>()

const typeCssMap = {
  warning: 'bg-orange-200/70 border-orange-300 shadow-orange-400',
  error: 'bg-red-200/70 border-red-300 shadow-red-400',
  message: 'bg-white/70 border-gray-200 shadow-gray-400',
  success: 'bg-green-300/60 border-green-200 shadow-green-400'
}

const titleCssMap = {
  warning: 'text-orange-700',
  error: 'text-red-700',
  message: 'text-gray-800',
  success: 'text-green-800'
}

const iconMap = {
  warning: ExclamationTriangleIcon,
  error: ExclamationCircleIcon,
  message: InformationCircleIcon,
  success: CheckCircleIcon
}
</script>

<template lang="pug">
.toast(
  class="grid gap-2 border shadow p-3 rounded backdrop-blur leading-none min-w-72"
  :class="typeCssMap[toast.type]"
)
  div(
    class="font-semibold flex items-center gap-2"
    :class="[titleCssMap[toast.type], positionCssMap[toast.type]]"
  )
    component(:is="iconMap[toast.type]" class="h-6")
    span {{ toast.title }}
  p {{ toast.text }}
</template>
