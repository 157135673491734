<script setup lang="ts">
import { useNProgress } from '@vueuse/integrations/useNProgress'
import 'nprogress/nprogress.css'

const { start, done } = useNProgress(0, { showSpinner: false })

useNuxtApp().hook('page:start', () => start())
useNuxtApp().hook('page:finish', () => done())
</script>

<template lang="pug">
</template>

<style lang="stylus">
#nprogress
  .bar 
    background: #2b8439
    height 3px
  .spinner-icon 
    border-top-color #2b8439
    border-left-color #2b8439
</style>
