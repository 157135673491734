<script setup lang="ts">
import {
  HomeIcon,
  ShoppingBagIcon,
  Squares2X2Icon,
  UserIcon,
  WrenchScrewdriverIcon
} from '@heroicons/vue/24/outline'
import {
  HomeIcon as HomeIconSolid,
  ShoppingBagIcon as ShoppingBagIconSolid,
  Squares2X2Icon as Squares2X2IconSolid,
  UserIcon as UserIconSolid,
  WrenchScrewdriverIcon as WrenchScrewdriverIconSolid
} from '@heroicons/vue/24/solid'

const isLoggedIn = computed(() => useAuthStore().isLoggedIn)

const userPageLink = computed(() => isLoggedIn.value ? '/user' : '/login')
const userPageText = computed(() => isLoggedIn.value ? 'لمونو من' : 'ورود')

const shoppingCartProdcutCount = computed(() => useShoppingCartStore().prodcutCount)
</script>

<template lang="pug">
nav.bottom-nav(class="fixed w-full grid grid-cols-5 bg-white z-10 bottom-0 shadow-[0px_3px_10px_0px_rgba(0,0,0,.6)]")
  BottomNavItem(
    to="/"
    :icon="HomeIcon"
    :iconActive="HomeIconSolid"
  ) خانه
  BottomNavItem(
    to="/products"
    :icon="Squares2X2Icon"
    :iconActive="Squares2X2IconSolid"
  ) محصولات
  BottomNavItem(
    to="/shopping-cart"
    :icon="ShoppingBagIcon"
    :iconActive="ShoppingBagIconSolid"
    :badge="shoppingCartProdcutCount"
  ) سبد خرید
  BottomNavItem(
    to="/support"
    :icon="WrenchScrewdriverIcon"
    :iconActive="WrenchScrewdriverIconSolid"
  ) پشتیبانی
  BottomNavItem(
    :to="userPageLink"
    :icon="UserIcon"
    :iconActive="UserIconSolid"
  ) {{ userPageText }}
</template>
