<script setup lang="ts">
const route = useRoute()

const list = ['/', '/products']
const headingTag = computed(() => list.includes(route.path))
</script>

<template lang="pug">
.logo(class="flex flex-col items-start justify-start gap-2")
  NuxtLink.logo(to="/")
    .logo-img(
      class="w-40 h-8 bg-contain bg-no-repeat lg:w-48 lg:h-8 bg-right-top"
      class="bg-[url('/img/lemono-logo.svg')]"
    )
    template(v-if="headingTag")
      h1(class="text-[.9rem] leading-none mt-2") {{ $t('common.slogan') }}
    template(v-else)
      span(class="text-[.9rem] leading-none mt-2 block") {{ $t('common.slogan') }}
</template>
