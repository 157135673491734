import { defineStore } from "pinia"
import type { ISearchResult, ISearchResultItem } from "shared"
import { z } from 'zod'
import { useUXStore } from "./UXStore"


const searchStateSchema = z.enum([
  'notSearchedYet',
  'noItemFound',
  'itemsFound',
  'loading'
])
type ISearchState = z.infer<typeof searchStateSchema>


export const useSearchStore = defineStore('searchStore', () => {
  const result = ref<ISearchResultItem[]>([])
  const state = ref<ISearchState>('notSearchedYet')

  const keyword = ref('')
  debouncedWatch(keyword, () => search(), { debounce: 1000 })

  const searchVisible = ref(false)
  const toggle = () => searchVisible.value = !searchVisible.value
  const close = () => searchVisible.value = false
  const show = () => searchVisible.value = true

  const uxStore = useUXStore()
  watch(searchVisible, newValue => {
    if (newValue && uxStore.menuVisible)
      uxStore.menuVisible = false
  })

  const search = async () => {
    result.value = []
    state.value = 'loading'

    if (keyword.value.length < 2) {
      state.value = 'noItemFound'
      return
    }

    const { data, error } = await useApi<ISearchResult>(`search/${keyword.value}`)

    if (error.value) {
      console.log(error)
      state.value = 'noItemFound'
    } else {
      result.value = data.value.result
      state.value = result.value.length > 0
        ? 'itemsFound'
        : 'noItemFound'
    }
  }

  return {
    keyword,
    result,
    toggle,
    show,
    close,
    search,
    searchVisible,
    state
  }
})
