<script setup lang="ts">
import type { ISearchResultItem } from 'shared/entities/search'
import { ArrowLeftIcon } from '@heroicons/vue/24/outline'

const emit = defineEmits(['itemClicked'])

const { item } = defineProps<{
  item: ISearchResultItem
}>()

const getSearchItemUrl = (): string => {
  const map = {
    product: () => `/product/${item._id}`,
    article: () => `/news/article/${item._id}`,
    guide: () => `/guide/${item._id}`
  }

  return map[item.section]()
}

const getSearchItemThumb = (): string => {
  if (item.image != undefined)
    return getItemThumb({ images: [item.image] }, 'sm', 'product')
  else
    return '/img/placeholder.webp'
}

const clicked = () => emit('itemClicked', item)
</script>

<template lang="pug">
NuxtLink.search-result-item(
  :to="getSearchItemUrl()"
  @click="clicked"
  class="group flex items-center rounded-lg cursor-pointer overflow-hidden h-24 backdrop-blur"
  class="hover:shadow"
)
  img(:src="getSearchItemThumb()" class="size-24 object-cover")
  .content(class="grid gap-2 p-4 text-lg flex-grow")
    div(class="font-semibold") {{ item.title }}
    div(class="truncate" v-html="item.text")
  div(class="mx-auto shrink-0")
    ArrowLeftIcon.arrow(
      class="h-5 hidden text-transparent transition-colors px-4 lg:block"
      class="group-hover:text-gray-500"
    )
</template>
