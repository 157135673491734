<script setup lang="ts">
import { useToastStore } from '~/store/toastStore'

type ToastContainerPosition =
  'top-left' | 'top-center' | 'top-right' |
  'bottom-left' | 'bottom-center' | 'bottom-right'

const { position = 'bottom-cneter' } = defineProps<{
  position?: ToastContainerPosition
}>()

const toastStore = useToastStore()
const { list } = storeToRefs(toastStore)

const positionCss: Record<ToastContainerPosition, string> = {
  'top-left': 'top- left-0',
  'top-center': 'top-0 left-0 -translate-x-1/2',
  'top-right': 'top-0 right-0',
  'bottom-left': 'bottom-0 left-0',
  'bottom-center': 'bottom-0 left-0 -translate-x-1/2',
  'bottom-right': 'bottom-0 right-0',
}
</script>

<template lang="pug">
template(v-if="list?.length > 0")
  .toast-container(
    class="fixed bottom-0 p-4 grid gap-3 z-30"
    :class="positionCss[position]"
  )
    template(v-for="toast in list")
      Toast(:toast="toast")
</template>
