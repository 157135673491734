<script setup lang="ts">
import { ShoppingBagIcon, UserIcon } from '@heroicons/vue/24/outline'
import { useShoppingCartStore } from '~/stores/shoppingCartStore'

const shoppingCartStore = useShoppingCartStore()
const { prodcutCount } = storeToRefs(shoppingCartStore)

const shoppingCartButtonPaddingLeft =
  computed(() => prodcutCount.value > 0 ? '.6rem' : '1rem')

const authStore = useAuthStore()
const { isLoggedIn } = storeToRefs(authStore)
</script>

<template lang="pug">
.user-buttons(class="flex items-center gap-2 lg:w-auto")
  template(v-if="!isLoggedIn")
    HeaderUserButton(to="/login")
      UserIcon(class="h-5")
      span ورود

  template(v-else)
    template(v-if="authStore.fullname != undefined")
      HeaderUserButton(to="/user")
        UserIcon(class="h-5")
        span {{ authStore.fullname }}
    template(v-else)
      HeaderUserButton(to="/login/details")
        UserIcon(class="h-5")
        span تکمیل ثبت‌نام

  HeaderUserButton(
    to="/shopping-cart" 
    :badge="prodcutCount > 0 ? prodcutCount : undefined"
    :style="{ paddingLeft: shoppingCartButtonPaddingLeft }"
  )
    ShoppingBagIcon(class="h-5")
    span(class="hidden lg:visible") سبدخرید
</template>
