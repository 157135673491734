<script setup lang="ts">
import { useSearchStore } from '~/stores/searchStore'

const searchStore = useSearchStore()

const HeaderLG = resolveComponent('HeaderLG')
const HeaderSM = resolveComponent('HeaderSM')

const header = shallowRef()

header.value = useDevice().isMobile
  ? HeaderSM
  : HeaderLG
</script>

<template lang="pug">
.main-layout(class="min-h-screen bg-[color:--bg-color]")
  ClientOnly
    PageLoadProgress
  //- Banner
  component(:is="header")
  slot
  div(class="h-52")
  BottomNav(class="md:hidden")
  Footer
  ToastContainer

  SearchPanel(v-if="searchStore.searchVisible")
</template>
