<script setup lang="ts">
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { useSearchStore } from '~/stores/searchStore'

const searchStore = useSearchStore()
const showSearch = () => searchStore.searchVisible = true
</script>

<template lang="pug">
.header-search
  .search-field(
    @click="showSearch"
    class="flex items-center gap-4 py-2 px-4"
    class="bg-white border border-gray-300 rounded-full text-gray-500 cursor-pointer"
    class="hover:border-[--green-color-dark] transition-all duration-200"
  )
    MagnifyingGlassIcon.h-5
    span جستجو در لمونو
</template>
