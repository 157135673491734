<script setup lang="ts">
import { LinkIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'

const showMore = ref(false)
</script>

<template lang="pug">
footer(class="relative text-white bg-[color:--footer-color] isolate")
  SocialLinks(class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2")

  .footer-body(
    :class="{ 'show-more': showMore }"
    class="group relative h-[30rem] overflow-hidden lg:h-auto [&.show-more]:h-auto"
    class="after:absolute after:left-0 after:bottom-0 after:content-[''] after:w-full after:h-[15rem]"
    class="after:bg-gradient-to-b after:from-transparent after:to-black/90 after:[&.show-more]:hidden"
    class="after:lg:hidden"
  )
    button.show-more(
      @click="showMore = true"
      class="group-[.show-more]:hidden"
      class="absolute bottom-20 left-1/2 -translate-x-1/2 bg-white/70 px-4 py-2 rounded-full text-black z-10 backdrop-blur-sm"
      class="text-sm lg:hidden"
    ) نمایش بیشتر

    div(class="container mx-auto")
      div(class="grid gap-12 pt-32 lg:pt-40 pb-12 lg:gap-32 lg:grid-cols-[1fr_1fr_15rem]")
        .about(class="grid gap-6")
          a.logo(href="https://lemono.co")
            img.block(clas="h-6"
              class="brightness-[10] grayscale"
              src="/img/lemono-logo.svg"
              width="108" height="19" loading="lazy"
              alt="لوگوی لمونو"
              area-label="رفتن به صفحه اصلی لمونو"
            )

          div(class="text-xl flex gap-3")
            InformationCircleIcon(class="h-6")
            span {{ $t('footer.about.about') }}

          div(class="text-base text-justify") {{ $t('footer.about.text') }}

        div(class="links grid gap-6")
          div(class="text-xl flex gap-3")
            LinkIcon(class="h-6")
            span {{ $t('footer.links.links') }}

          ul(
            class="grid gap-4 grid-cols-2 gap-1 list-disc list-inside"
            class="hover:[&_a]:text-[--green-color] focus:[&_a]:text-[--green-color]"
          )
            li: NuxtLink(to="/news") {{ $t('footer.links.news') }}
            li: NuxtLink(to="/guide/participation") {{ $t('footer.links.collab') }}
            li: NuxtLink(to="/guide/privacy-policy") {{ $t('footer.links.privacyPolicy') }}
            li: NuxtLink(to="/support/service-request") {{ $t('footer.links.serviceRequest') }}
            li: NuxtLink(to="/products") {{ $t('footer.links.products') }}
            li: NuxtLink(to="/about") {{ $t('footer.links.aboutUs') }}
            li: NuxtLink(to="/contact") {{ $t('footer.links.contactUs') }}
            li: NuxtLink(to="/support") {{ $t('footer.links.support') }}
            li: NuxtLink(to="/guide") {{ $t('footer.links.faq') }}
            li: NuxtLink(to="/guide/guarantee-conditions") شرایط گارانتی

        div(class="flex flex-col gap-10")
          div(class="flex justify-center")
            .license(class="bg-white px-4 py-4 grid grid-cols-2 gap-5 justify-items-center")
              a(href="#")
                img(
                  src="/img/samandehi.webp"
                  loading="lazy"
                  alt="نشان ملی ثبت"
                  class="size-24 object-contain"
                )
              a(href="https://trustseal.enamad.ir/?id=93050&code=KV4pvK0FroyCbHNOSCZ1" target="_blank")
                img(
                  src="/img/enamad.png"
                  loading="lazy"
                  alt="نماد اعتماد الکترونیک"
                  class="size-24 object-contain"
                )

          .app
            div(class="hidden text-lg lg:block") {{ $t('footer.app.downloadApp') }}
            div(class="flex gap-2 mt-4 justify-center")
              a(href="#" class="w-32 h-10")
                img(
                  src="/img/google-play-badge.png"
                  loading="lazy" 
                  alt="نماد تصویر گوگل‌پلی - فروشگاه نرم‌افزار شرکت گوگل"
                  area-label="باز کردن صفحه نرم‌افزار لمونو در فروشگاه گوگل پلی"
                  class="w-32 h-10"
                )
              a(href="#" class="w-32 h-10")
                img(
                  src="/img/app-store-badge.webp"
                  loading="lazy"
                  alt="نماد تصویری آپ‌استور - فروشگاه نرم‌افزار شرکت اپل"
                  area-label="باز کردن صفحه نرم‌افزار لمونو در فروشگاه اپ‌استور اپل"
                  class="w-32 h-10"
                )

    .bottom-part(class="border-t border-stone-400 py-10")
      div(
        class="container mx-auto flex flex-col justify-center gap-y-6"
        class="lg:flex-row lg:justify-between"
      )
        ul.menu(
          class="flex gap-x-6 gap-y-2 flex-wrap justify-center lg:flex-row"
          class="hover:[&_a]:text-[--green-color] focus:[&_a]:text-[--green-color]"
        )
          li: NuxtLink(to="/") {{ $t('nav.home') }}
          li: NuxtLink(to="/products") {{ $t('nav.products') }}
          li: NuxtLink(to="/support") {{ $t('nav.support') }}
          li: NuxtLink(to="/guide") {{ $t('nav.purchaseGuide') }}
          li: NuxtLink(to="/news") {{ $t('nav.news') }}
          li: NuxtLink(to="/about") {{ $t('nav.aboutUs') }}
          li: NuxtLink(to="/contact") {{ $t('nav.contactUs') }}

        .copyright(class="flex justify-center dir-ltr en-font")
          span © Lemono 2024
</template>
